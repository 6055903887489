import React from 'react'
import PropTypes from 'prop-types'
import styles from './BlogPageTitle.module.scss'

const BlogPageTitle = props => <h1 className={styles.title}>{props.text}</h1>

BlogPageTitle.propTypes = {
  text: PropTypes.string.isRequired
}

export default BlogPageTitle
