import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { VelocityComponent } from 'velocity-react'
import Icon from '../Icon/Icon'
import DropdownAnimation from '../DropdownAnimation/DropdownAnimation'
import styles from './BlogCategories.module.scss'

const tr = {
  viewAll: {
    es: 'Ver todo',
    ca: 'Veure tot',
    en: 'View all',
    fr: 'Voir tout'
  }
}

/**
 * @param {Array} edges
 */
function createByIdObj (edges) {
  let allIds = []
  let byId = {}

  edges.forEach(({ node }) => {
    const { id, count, name, slug, parentElement } = node

    allIds.push(id)
    byId[id] = {
      id,
      count,
      name,
      slug,
      parentElement,
      childCategories: []
    }

    if (parentElement) {
      const parentId = parentElement.id
      byId[parentId] = {
        ...byId[parentId],
        childCategories: [...byId[parentId].childCategories, id]
      }
    }
  })

  return { allIds, byId }
}

class BlogCategories extends Component {
  constructor (props) {
    super(props)
    this.state = {
      visibleDropdownId: null
    }
  }

  toggleVisibility (id) {
    const { visibleDropdownId } = this.state
    if (visibleDropdownId === id) {
      this.setState({ visibleDropdownId: null })
    } else {
      this.setState({ visibleDropdownId: id })
    }
  }

  render () {
    const categories = createByIdObj(this.props.edges)
    const { allIds, byId } = categories
    const { visibleDropdownId } = this.state
    const { homePagePath, blogPagePath, locale, location } = this.props
    const { pathname } = location
    return (
      <div className={styles.container}>
        <ul className={styles.parentList}>
          {blogPagePath && (
            <li className={styles.blogLinkItem}>
              <Link to={blogPagePath} className={styles.blogLink}>
                {tr.viewAll[locale]}
              </Link>
            </li>
          )}
          {allIds.map(id => {
            const item = byId[id]
            const { parentElement } = item

            if (!parentElement) {
              const { id, name, slug, childCategories } = item
              const content = (
                <Fragment>
                  <span className={styles.text}>{name}</span>
                </Fragment>
              )
              const hasActiveChild = childCategories.some(childId => {
                const childItem = byId[childId]
                return `${homePagePath}${slug}/${childItem.slug}` === pathname
              })
              return (
                <li key={id} className={styles.parentListItem}>
                  <Fragment>
                    {childCategories.length === 0 ? (
                      <Link
                        to={`${homePagePath}${slug}`}
                        className={styles.parentLink}
                        activeClassName={styles.active}
                      >
                        {content}
                      </Link>
                    ) : (
                      <button
                        type='button'
                        onClick={() => this.toggleVisibility(id)}
                        className={`${styles.parentLink} ${
                          visibleDropdownId === id ? styles.dropdownOpen : ''
                        } ${hasActiveChild ? styles.active : ''}`}
                      >
                        {content}
                        <Icon name='goldCaret' />
                      </button>
                    )}
                    {childCategories.length > 0 && (
                      <DropdownAnimation
                        animation={
                          visibleDropdownId === id ? 'slideDown' : 'slideUp'
                        }
                        easing='easeInOutSine'
                        duration={300}
                        delay={visibleDropdownId === id ? 0 : 300}
                      >
                        <div className={styles.childCategories}>
                          <VelocityComponent
                            animation={{
                              opacity: visibleDropdownId === id ? 1 : 0
                            }}
                            duration={400}
                            delay={visibleDropdownId === id ? 200 : 0}
                          >
                            <div className={styles.childCategoriesInner}>
                              <ul className={styles.childList}>
                                <li>
                                  <Link
                                    to={`${homePagePath}${slug}`}
                                    className={styles.childLink}
                                    activeClassName={styles.active}
                                  >
                                    {content}
                                  </Link>
                                </li>
                                {childCategories.map(childId => {
                                  const childItem = byId[childId]
                                  return (
                                    <li key={childItem.id}>
                                      <Link
                                        to={`${homePagePath}${slug}/${
                                          childItem.slug
                                        }`}
                                        className={styles.childLink}
                                        activeClassName={styles.active}
                                      >
                                        <span className={styles.text}>
                                          {childItem.name}
                                        </span>
                                      </Link>
                                    </li>
                                  )
                                })}
                              </ul>
                            </div>
                          </VelocityComponent>
                        </div>
                      </DropdownAnimation>
                    )}
                  </Fragment>
                </li>
              )
            }
          })}
        </ul>
      </div>
    )
  }
}

BlogCategories.propTypes = {
  edges: PropTypes.array.isRequired,
  homePagePath: PropTypes.string.isRequired,
  blogPagePath: PropTypes.string,
  locale: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired
}

export default BlogCategories
