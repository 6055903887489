import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import Icon from '../Icon/Icon'
import styles from './FeaturedPost.module.scss'

const FeaturedPost = ({ data }) => {
  const { acf, categories, path, title } = data
  const { subtitle, featuredMedia } = acf
  let sizes = null
  let duotoneSizes = null
  let altText = ''
  let localFile = null
  let sourceUrl = null

  if (featuredMedia) {
    altText = featuredMedia.altText
    localFile = featuredMedia.localFile
    sourceUrl = featuredMedia.sourceUrl
    sizes = localFile ? localFile.childImageSharp.croppedSizes : null
    duotoneSizes = localFile
      ? localFile.childImageSharp.croppedSizesDuotone
      : null
  }
  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <div className={styles.image}>
          {sizes && <Img fluid={sizes} alt={altText} />}
          {!sizes && sourceUrl && (
            <div className={styles.imageBackup}>
              <img src={sourceUrl} alt={altText} />
            </div>
          )}
          {duotoneSizes && (
            <div className={styles.duotoneImage}>
              <Img fluid={duotoneSizes} alt={altText} />
            </div>
          )}
          {!sizes && !sourceUrl && (
            <div className={styles.imagePlaceholder}>
              <Icon name='mainHeaderBrand' />
            </div>
          )}
        </div>
        {categories && (
          <ul className={styles.categories}>
            {categories.map(({ id, name }) => {
              return <li key={id}>{name}</li>
            })}
          </ul>
        )}
        <div className={styles.title}>
          <Link to={path}>{title}</Link>
        </div>
        {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
      </div>
    </div>
  )
}

FeaturedPost.propTypes = {
  data: PropTypes.object.isRequired
}

export default FeaturedPost
