import React from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import ReactPagination from 'react-js-pagination'
import Icon from '../Icon/Icon'
import styles from './BlogPagination.module.scss'

const BlogPagination = ({ currentPage, path, totalCount }) => {
  return (
    <div className={styles.container}>
      <ReactPagination
        activeClass={styles.active}
        activeLinkClass={styles.activeLink}
        activePage={currentPage}
        disabledClass={styles.disabled}
        hideFirstLastPages
        innerClass={styles.list}
        itemsCountPerPage={12}
        linkClassNext={styles.next}
        linkClassPrev={styles.prev}
        nextPageText={<Icon name='smallArrowRightGold' />}
        onChange={pageNumber => {
          const formattedPath =
            path.substring(0, path.length) === '/'
              ? `${path}${pageNumber}`
              : `${path}/${pageNumber}`
          navigate(pageNumber === 1 ? path : formattedPath)
        }}
        pageRangeDisplayed={10}
        prevPageText={<Icon name='smallArrowLeftGold' />}
        totalItemsCount={totalCount}
      />
    </div>
  )
}

BlogPagination.propTypes = {
  currentPage: PropTypes.number,
  path: PropTypes.string,
  totalCount: PropTypes.number
}

export default BlogPagination
